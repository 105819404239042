import React from 'react'
import { Link } from 'react-router-dom'

const SkillTwo = () => {
	return (
		<>
			{/*skill-area start*/}
			<div
				className='skill-area pd-top-120 pd-bottom-120'
				style={{ background: "#f9f9f9" }}
			>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-lg-6 order-lg-2'>
							<div className='row'>
								<div className='col-md-6'>
									<div className='thumb'>
										<img
											className='w-100'
											src='assets/img/about/4.png'
											alt='Transpro'
										/>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='thumb img-2'>
										<img
											className='w-100'
											src='assets/img/about/5.png'
											alt='Transpro'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-6 order-lg-1'>
							<div className='section-title mt-lg-0 mt-5'>
								<h4 className=' style-2'>OUR SERVICE</h4>
								<h2 className='title'>About Full Truck Load</h2>
								<p>
									Our Full Truckload service provides dedicated transportation for large shipments, ensuring that your cargo has the entire truck to itself. Benefit from streamlined logistics, reduced handling, and direct, on-time deliveries. This service is perfect for bulk shipments and offers enhanced security and efficiency. Enjoy a tailored solution that meets your volume needs.
								</p>
							</div>
							<div className='btn-wrapper animated fadeInUpBig text-left'>
								<Link to='/contact' className='boxed-btn btn-bounce mt-3'>
									Contact us
								</Link>
							</div>

						</div>
					</div>
				</div>
			</div>
			{/*skill-area end*/}
		</>
	)
}

export default SkillTwo