import React, { Fragment, Suspense, useState } from "react";
import Preloader from "../elements/Preloader";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase.config";
const BreadCrumbAbout = React.lazy(() => import("../components/BreadCrumbAbout"));
const ContactInner = React.lazy(() => import("../components/ContactInner"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    msg: "",
    phn: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString(
      "en-US",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }
    )

  });

  const handleResponse = async () => {
    if (data.name === "" || data.email === "" || data.subject === "" || data.phn === "" || data.msg === "") {
      alert("Please Enter Your Details ")
    }
    const dataRef = collection(db, 'ContactResponse')

    try {
      await addDoc(dataRef, data)
      alert("Thank You for your feedback")
    } catch (error) {
      console.log(error)
    }

  }


  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarOne />

          {/* BreadCrumbAbout */}
          <BreadCrumbAbout title={"CONTACT US"} />

          {/* Breadcrumb */}
          <ContactInner data={data} setData={setData} handleResponse={handleResponse} />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}

        </Suspense>
      </Fragment>
    </>
  );
};

export default Contact;
