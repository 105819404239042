import React from 'react'
import '../style.css'
const TrackDetails = ({ status }) => {
	return (
		<div className=' trackcontainer ' >
			<div className='box bg-white py-4' >

				<div className='status-bars bg-gray-100 px-6 py-3 border-y border-gray-200' >
					<p>Location</p>
					<p>Date</p>
					<p>Status</p>
					<p>Remark</p>

				</div>
				{status?.orderActivity?.map((s) => (
					<div className=' status-infos px-6 py-3' >
						<p>{s?.location}</p>
						<p>{s?.time?.toDate().toString().slice(0, 25)}</p>
						<p className=' status-text text-green-600 capitalize' >{s?.status}</p>
						<p>{s?.remark}</p>
					</div>
				))}
			</div>
		</div>
	)
}

export default TrackDetails