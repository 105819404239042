import React, { Fragment, Suspense, useState } from "react";
import Preloader from "../elements/Preloader";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import TrackDetails from "../components/TrackDetails";
const BreadCrumbAbout = React.lazy(() => import("../components/BreadCrumbAbout"));
const RequestQuoteTwo = React.lazy(() => import("../components/RequestQuoteTwo"));
const FooterBottomOne = React.lazy(() =>
	import("../components/FooterBottomOne")
);
const WhyChooseUsTwo = React.lazy(() =>
	import("../components/WhyChooseUsTwo")
);
const AboutThree = React.lazy(() => import("../components/AboutThree"));
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const SkillSix = React.lazy(() => import("../components/SkillSix"));
const ServiceOneAll = React.lazy(() => import("../components/ServiceOneAll"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const Track = () => {
	const [status, setStatus] = useState({})
	const [giveNumber, setGiveNumber] = useState("")
	const [activeTab, setActiveTab] = useState("LR")
	const [trackingClicked, setTrackingClicked] = useState(false)
	const fetchStatus = async () => {
		if (activeTab === "LR") {
			const q = query(collection(db, "logisticOrder"), where("lrno", "==", giveNumber))
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				setStatus(doc.data())
				console.log(doc.data())

			});

			setTrackingClicked(true)
		}
		else if (activeTab === "Forwarding") {
			const q = query(collection(db, "logisticOrder"), where("forwardingNumber", "==", giveNumber))
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				setStatus(doc.data())
				console.log(doc.data())

			});

			setTrackingClicked(true)
		}
		else {
			const q = query(collection(db, "logisticOrder"), where("orderID", "==", giveNumber))
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				setStatus(doc.data())
				console.log(doc.data())

			});

			setTrackingClicked(true)
		}
	}
	return (
		<>

			<Fragment>
				<Suspense fallback={<Preloader />}>
					{/* Search Popup */}
					<SearchPopup />

					{/* Navbar One */}
					<NavbarOne />

					{/* BreadCrumbAbout */}
					{/* <BreadCrumbAbout title={"Track Your Order"} /> */}

					{/* Service One All */}
					<div className='mg-top-120 pd-bottom-60'>
						<RequestQuoteTwo activeTab={activeTab} setActiveTab={setActiveTab}
							status={status} setStatus={setStatus}
							giveNumber={giveNumber} setGiveNumber={setGiveNumber}
							fetchStatus={fetchStatus} trackingClicked={trackingClicked}
							setTrackingClicked={setTrackingClicked} />
						{Object.keys(status).length !== 0 && <p className='text-center font-[GilroyMedium] my-3 cursor-pointer' >...more details</p>}
						{Object.keys(status).length !== 0 && <TrackDetails status={status} />}
					</div>

					<div className='pd-top-120 pd-bottom-120'>
						{/* <AboutThree /> */}
					</div>

					{/* Footer One */}
					<FooterOne />

					{/* Footer Bottom One */}
				</Suspense>{" "}
			</Fragment>
		</>
	)
}

export default Track