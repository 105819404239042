import React, { Fragment, Suspense, useState } from "react";
import Preloader from "../elements/Preloader";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase.config'
import { pinCodeList } from "../data/pincodeList"
import TrackDetails from "../components/TrackDetails";
import ServiceTable from "../components/ServiceTable";
import "../style.css"
const BannerOne = React.lazy(() => import("../components/BannerOne"));
const AboutOne = React.lazy(() => import("../components/AboutOne"));
const BlogOne = React.lazy(() => import("../components/BlogOne"));
const ContactOne = React.lazy(() => import("../components/ContactOne"));
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const FeatureOne = React.lazy(() => import("../components/FeatureOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const PartnerOne = React.lazy(() => import("../components/PartnerOne"));
const PortfolioOne = React.lazy(() => import("../components/PortfolioOne"));
const ServiceOne = React.lazy(() => import("../components/ServiceOne"));
const TeamOne = React.lazy(() => import("../components/TeamOne"));
const RequestQuoteOne = React.lazy(() => import("../components/RequestQuoteOne"));
const WhyChooseUsOne = React.lazy(() => import("../components/WhyChooseUsTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const HomeOne = () => {

  const [pinCode, setPinCode] = useState("")
  const [pinCodeDetail, setPinCodeDetail] = useState({})
  const handleCheckPinCode = () => {
    console.log("click")
    if (pinCode.length === 0 || pinCode.length !== 6) {
      alert('Please enter a valid Pin Code')
    }
    else {
      console.log("hii")
      pinCodeList.map((pin) => {
        if (pin.Pin === Number(pinCode)) {
          setPinCodeDetail(pin)
        }
      })
      setPinCode("")
    }
  }


  // 
  const [status, setStatus] = useState({})
  const [giveNumber, setGiveNumber] = useState("")
  const [activeTab, setActiveTab] = useState("LR")
  const [trackingClicked, setTrackingClicked] = useState(false)
  const fetchStatus = async () => {
    if (activeTab === "LR") {
      const q = query(collection(db, "logisticOrder"), where("lrno", "==", giveNumber))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setStatus(doc.data())
        console.log(doc.data())

      });

      setTrackingClicked(true)
    }
    else if (activeTab === "Forwarding") {
      const q = query(collection(db, "logisticOrder"), where("forwardingNumber", "==", giveNumber))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setStatus(doc.data())
        console.log(doc.data())

      });

      setTrackingClicked(true)
    }
    else {
      const q = query(collection(db, "logisticOrder"), where("orderID", "==", giveNumber))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setStatus(doc.data())
        console.log(doc.data())

      });

      setTrackingClicked(true)
    }
  }

  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarOne />

          {/* Banner One */}
          <BannerOne />

          {/* Feature One */}
          <FeatureOne />
          <div
            className='call-to-contact-area home mb-120'>
            <RequestQuoteOne
              activeTab={activeTab} setActiveTab={setActiveTab}
              status={status} setStatus={setStatus}
              giveNumber={giveNumber} setGiveNumber={setGiveNumber}
              fetchStatus={fetchStatus} trackingClicked={trackingClicked}
              setTrackingClicked={setTrackingClicked} handleCheckPinCode={handleCheckPinCode} pinCodeDetail={pinCodeDetail} pinCode={pinCode} setPinCode={setPinCode}
            />
            {Object.keys(pinCodeDetail).length !== 0 && <ServiceTable pinCodeDetail={pinCodeDetail} pinCode={pinCode} />}
          </div>

          {Object.keys(status).length !== 0 && <p className='text-center font-[GilroyMedium] my-3 cursor-pointer' >...more details</p>}
          {Object.keys(status).length !== 0 && <TrackDetails status={status} />}
          {/* About One */}
          <AboutOne />

          {/* Service One */}
          <ServiceOne />

          {/* Why Choose Us One */}
          <WhyChooseUsOne />

          {/* Counter One */}

          {/* <CounterOne /> */}

          {/* Team One */}
          {/* <TeamOne /> */}

          {/* Contact One */}

          {/* Testimonial One */}
          {/* <TestimonialOne /> */}

          {/* Portfolio One */}
          {/* <PortfolioOne /> */}

          {/* Blog One */}
          {/* <BlogOne /> */}

          {/* Partner One */}
          {/* <PartnerOne /> */}

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          {/* <FooterBottomOne /> */}
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeOne;
