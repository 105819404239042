import React from 'react'
import "../style.css"
import { CiCircleCheck } from "react-icons/ci";
import { RxCrossCircled } from "react-icons/rx";
const ServiceTable = ({ pinCodeDetail, pinCode }) => {
	return (
		<div class="trackcontainer my-6">
			<div class="box">
				<h3 className='' ><span>{pinCodeDetail.Pin} is valid Pin Code,</span> Serviceability listed below </h3>
				<div class="status-barss">
					<p>Pin Code</p>
					<p>State</p>
					<p>City</p>
					<p>Service</p>
					<p>ODA</p>
				</div>
				<div class="status-infoss px-6 py-3">
					<p>{pinCodeDetail?.Pin}</p>
					<p>{pinCodeDetail?.FacilityState}</p>
					<p>{pinCodeDetail?.FacilityCity}</p>
					<p style={{ color: "green" }} className="text-[1.5rem]" ><CiCircleCheck /> </p>
					<p  > {pinCodeDetail.ODA === "ODA" ? <CiCircleCheck style={{ color: "green" }} className="right" /> : <RxCrossCircled style={{ color: "red" }} className="text-[1.5rem] text-red-600" />} </p>
				</div>
			</div>
		</div>
	)
}

export default ServiceTable