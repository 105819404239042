import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import SkillTwo from "../components/SkillTwo";
const BlogView = React.lazy(() => import("../components/BlogView"));
const AboutThree = React.lazy(() => import("../components/AboutOne"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const WhyChooseUsTwo = React.lazy(() => import("../components/WhyChooseUsOne"));
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const Blog = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarOne />

          {/* Breadcrumb */}
          <Breadcrumb title={"Full Truck Load "} />

          <SkillTwo />
          <WhyChooseUsTwo />
          {/* Blog View */}
          {/* <BlogView /> */}
          <div className='pd-top-120 pd-bottom-120'>
            <AboutThree />
          </div>

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}

        </Suspense>
      </Fragment>
    </>
  );
};

export default Blog;
